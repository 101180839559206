import React, { useEffect, useState } from 'react'
import Amplify from '@aws-amplify/core'
import API from '@aws-amplify/api'
import awsconfig from './aws-exports'
import * as queries from './graphql/queries'
import moment from 'moment'
import _debounce from 'lodash.debounce'

import ScoreCard from './ScoreCard'

Amplify.configure(awsconfig)

function DateNavigationButton(props) {
  const { children, className, ...rest } = props
  const cn =
    'text-bold bg-red-300 hover:bg-red-400 shadow focus:outline-none focus:shadow-outline font-bold px-3 py-1 rounded-full'
  const cn1 = `${cn}${className ? ' ' + className : ''}`
  return (
    <button className={cn1} {...rest}>
      {children}
    </button>
  )
}
function App() {
  const [date, setDate] = useState(moment())
  const [lastDate, setLastDate] = useState(null)
  const [fetching, setFetching] = useState(false)
  const nextDay = () => setDate(d => moment(d).add(1, 'day'))
  const prevDay = () => setDate(d => moment(d).subtract(1, 'day'))

  // const [scores, setScores] = useState(result.data.scores)

  const [scores, setScores] = useState({ games: [], numGames: 0 })
  useEffect(() => {
    const fetchScores = async () => {
      setFetching(true)
      setLastDate(moment(date))
      const result = await API.graphql({
        query: queries.scores,
        // variables: { query: { date: '20190212' } }
        variables: { query: { date: date.format('YYYYMMDD') } }
      })
      setFetching(false)
      setScores(result.data.scores)
    }
    if (fetching || date.isSame(lastDate)) {
      return
    }
    fetchScores()
    return () => {
      console.log('changing')
    }
  }, [fetching, date, lastDate])

  return (
    <div className="mx-auto p-4 md:p-8 text-gray-800 antialiased">
      <h1 className="text-center font-bold text-gray-900 text-2xl">
        NBA Scoreboard
      </h1>
      <div className="max-w-md mx-auto flex justify-center items-center my-4">
        <div>
          <DateNavigationButton onClick={prevDay}>
            <svg viewBox="0 0 20 20" className="w-4">
              <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z" />
            </svg>
          </DateNavigationButton>
        </div>
        <div className="mx-8 w-1/2 text-center">
          {date.format('dddd, MMM D')}
        </div>
        <div>
          <DateNavigationButton onClick={nextDay}>
            <svg viewBox="0 0 20 20" className="w-4">
              <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
            </svg>
          </DateNavigationButton>
        </div>
      </div>
      {scores.numGames > 0 ? (
        <div className="grid grid-flow-row grid-cols-1 sm:grid-cols-3 lg:grid-cols-2 gap-4">
          {scores.games.map(game => (
            <ScoreCard game={game} key={game.gameId} />
          ))}
        </div>
      ) : (
        <h2 className="mx-auto w-1/2 text-center text-gray-600 text-xs uppercase font-extrabold tracking-wide">
          No games on this day.
        </h2>
      )}
    </div>
  )
}

export default App

const result = {
  data: {
    scores: {
      numGames: 11,
      games: [
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900807',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T00:00:00.000Z',
          endTimeUTC: '2020-02-13T02:31:00.000Z',
          clock: '',
          attendance: '16200',
          vTeam: {
            teamId: '1610612737',
            triCode: 'ATL',
            score: '105',
            linescore: [
              {
                score: '19'
              },
              {
                score: '29'
              },
              {
                score: '32'
              },
              {
                score: '25'
              }
            ],
            team: {
              city: 'Atlanta',
              fullName: 'Atlanta Hawks',
              nickname: 'Hawks'
            }
          },
          hTeam: {
            teamId: '1610612739',
            triCode: 'CLE',
            score: '127',
            linescore: [
              {
                score: '33'
              },
              {
                score: '32'
              },
              {
                score: '33'
              },
              {
                score: '29'
              }
            ],
            team: {
              city: 'Cleveland',
              fullName: 'Cleveland Cavaliers',
              nickname: 'Cavaliers'
            }
          }
        },
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900808',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T00:00:00.000Z',
          endTimeUTC: '2020-02-13T02:38:00.000Z',
          clock: '',
          attendance: '16607',
          vTeam: {
            teamId: '1610612765',
            triCode: 'DET',
            score: '112',
            linescore: [
              {
                score: '20'
              },
              {
                score: '35'
              },
              {
                score: '26'
              },
              {
                score: '27'
              },
              {
                score: '4'
              }
            ],
            team: {
              city: 'Detroit',
              fullName: 'Detroit Pistons',
              nickname: 'Pistons'
            }
          },
          hTeam: {
            teamId: '1610612753',
            triCode: 'ORL',
            score: '116',
            linescore: [
              {
                score: '32'
              },
              {
                score: '27'
              },
              {
                score: '28'
              },
              {
                score: '21'
              },
              {
                score: '8'
              }
            ],
            team: {
              city: 'Orlando',
              fullName: 'Orlando Magic',
              nickname: 'Magic'
            }
          }
        },
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900809',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T00:30:00.000Z',
          endTimeUTC: '2020-02-13T02:51:00.000Z',
          clock: '',
          attendance: '15823',
          vTeam: {
            teamId: '1610612761',
            triCode: 'TOR',
            score: '91',
            linescore: [
              {
                score: '19'
              },
              {
                score: '21'
              },
              {
                score: '25'
              },
              {
                score: '26'
              }
            ],
            team: {
              city: 'Toronto',
              fullName: 'Toronto Raptors',
              nickname: 'Raptors'
            }
          },
          hTeam: {
            teamId: '1610612751',
            triCode: 'BKN',
            score: '101',
            linescore: [
              {
                score: '23'
              },
              {
                score: '29'
              },
              {
                score: '23'
              },
              {
                score: '26'
              }
            ],
            team: {
              city: 'Brooklyn',
              fullName: 'Brooklyn Nets',
              nickname: 'Nets'
            }
          }
        },
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900810',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T00:30:00.000Z',
          endTimeUTC: '2020-02-13T02:59:00.000Z',
          clock: '',
          attendance: '17018',
          vTeam: {
            teamId: '1610612749',
            triCode: 'MIL',
            score: '111',
            linescore: [
              {
                score: '20'
              },
              {
                score: '25'
              },
              {
                score: '26'
              },
              {
                score: '40'
              }
            ],
            team: {
              city: 'Milwaukee',
              fullName: 'Milwaukee Bucks',
              nickname: 'Bucks'
            }
          },
          hTeam: {
            teamId: '1610612754',
            triCode: 'IND',
            score: '118',
            linescore: [
              {
                score: '34'
              },
              {
                score: '34'
              },
              {
                score: '14'
              },
              {
                score: '36'
              }
            ],
            team: {
              city: 'Indiana',
              fullName: 'Indiana Pacers',
              nickname: 'Pacers'
            }
          }
        },
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900811',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T00:30:00.000Z',
          endTimeUTC: '2020-02-13T02:57:00.000Z',
          clock: '',
          attendance: '18835',
          vTeam: {
            teamId: '1610612764',
            triCode: 'WAS',
            score: '114',
            linescore: [
              {
                score: '25'
              },
              {
                score: '21'
              },
              {
                score: '31'
              },
              {
                score: '37'
              }
            ],
            team: {
              city: 'Washington',
              fullName: 'Washington Wizards',
              nickname: 'Wizards'
            }
          },
          hTeam: {
            teamId: '1610612752',
            triCode: 'NYK',
            score: '96',
            linescore: [
              {
                score: '24'
              },
              {
                score: '26'
              },
              {
                score: '23'
              },
              {
                score: '23'
              }
            ],
            team: {
              city: 'New York',
              fullName: 'New York Knicks',
              nickname: 'Knicks'
            }
          }
        },
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900812',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T01:00:00.000Z',
          endTimeUTC: '2020-02-13T03:22:00.000Z',
          clock: '',
          attendance: '16889',
          vTeam: {
            teamId: '1610612757',
            triCode: 'POR',
            score: '104',
            linescore: [
              {
                score: '27'
              },
              {
                score: '36'
              },
              {
                score: '19'
              },
              {
                score: '22'
              }
            ],
            team: {
              city: 'Portland',
              fullName: 'Portland Trail Blazers',
              nickname: 'Trail Blazers'
            }
          },
          hTeam: {
            teamId: '1610612763',
            triCode: 'MEM',
            score: '111',
            linescore: [
              {
                score: '31'
              },
              {
                score: '36'
              },
              {
                score: '25'
              },
              {
                score: '19'
              }
            ],
            team: {
              city: 'Memphis',
              fullName: 'Memphis Grizzlies',
              nickname: 'Grizzlies'
            }
          }
        },
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900813',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T01:00:00.000Z',
          endTimeUTC: '2020-02-13T03:35:00.000Z',
          clock: '',
          attendance: '18978',
          vTeam: {
            teamId: '1610612766',
            triCode: 'CHA',
            score: '115',
            linescore: [
              {
                score: '28'
              },
              {
                score: '22'
              },
              {
                score: '31'
              },
              {
                score: '34'
              }
            ],
            team: {
              city: 'Charlotte',
              fullName: 'Charlotte Hornets',
              nickname: 'Hornets'
            }
          },
          hTeam: {
            teamId: '1610612750',
            triCode: 'MIN',
            score: '108',
            linescore: [
              {
                score: '36'
              },
              {
                score: '30'
              },
              {
                score: '21'
              },
              {
                score: '21'
              }
            ],
            team: {
              city: 'Minnesota',
              fullName: 'Minnesota Timberwolves',
              nickname: 'Timberwolves'
            }
          }
        },
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900814',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T01:30:00.000Z',
          endTimeUTC: '2020-02-13T03:54:00.000Z',
          clock: '',
          attendance: '19842',
          vTeam: {
            teamId: '1610612758',
            triCode: 'SAC',
            score: '111',
            linescore: [
              {
                score: '28'
              },
              {
                score: '24'
              },
              {
                score: '25'
              },
              {
                score: '34'
              }
            ],
            team: {
              city: 'Sacramento',
              fullName: 'Sacramento Kings',
              nickname: 'Kings'
            }
          },
          hTeam: {
            teamId: '1610612742',
            triCode: 'DAL',
            score: '130',
            linescore: [
              {
                score: '32'
              },
              {
                score: '31'
              },
              {
                score: '33'
              },
              {
                score: '34'
              }
            ],
            team: {
              city: 'Dallas',
              fullName: 'Dallas Mavericks',
              nickname: 'Mavericks'
            }
          }
        },
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900815',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T02:00:00.000Z',
          endTimeUTC: '2020-02-13T04:21:00.000Z',
          clock: '',
          attendance: '15216',
          vTeam: {
            teamId: '1610612744',
            triCode: 'GSW',
            score: '106',
            linescore: [
              {
                score: '24'
              },
              {
                score: '26'
              },
              {
                score: '26'
              },
              {
                score: '30'
              }
            ],
            team: {
              city: 'Golden State',
              fullName: 'Golden State Warriors',
              nickname: 'Warriors'
            }
          },
          hTeam: {
            teamId: '1610612756',
            triCode: 'PHX',
            score: '112',
            linescore: [
              {
                score: '34'
              },
              {
                score: '24'
              },
              {
                score: '29'
              },
              {
                score: '25'
              }
            ],
            team: {
              city: 'Phoenix',
              fullName: 'Phoenix Suns',
              nickname: 'Suns'
            }
          }
        },
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900816',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T02:00:00.000Z',
          endTimeUTC: '2020-02-13T04:23:00.000Z',
          clock: '',
          attendance: '18306',
          vTeam: {
            teamId: '1610612748',
            triCode: 'MIA',
            score: '101',
            linescore: [
              {
                score: '28'
              },
              {
                score: '24'
              },
              {
                score: '22'
              },
              {
                score: '27'
              }
            ],
            team: {
              city: 'Miami',
              fullName: 'Miami Heat',
              nickname: 'Heat'
            }
          },
          hTeam: {
            teamId: '1610612762',
            triCode: 'UTA',
            score: '116',
            linescore: [
              {
                score: '25'
              },
              {
                score: '22'
              },
              {
                score: '32'
              },
              {
                score: '37'
              }
            ],
            team: {
              city: 'Utah',
              fullName: 'Utah Jazz',
              nickname: 'Jazz'
            }
          }
        },
        {
          seasonStageId: 2,
          seasonYear: '2019',
          gameId: '0021900817',
          isGameActivated: false,
          startTimeUTC: '2020-02-13T03:00:00.000Z',
          endTimeUTC: '2020-02-13T05:48:00.000Z',
          clock: '',
          attendance: '19860',
          vTeam: {
            teamId: '1610612747',
            triCode: 'LAL',
            score: '120',
            linescore: [
              {
                score: '23'
              },
              {
                score: '38'
              },
              {
                score: '23'
              },
              {
                score: '27'
              },
              {
                score: '9'
              }
            ],
            team: {
              city: 'Los Angeles',
              fullName: 'Los Angeles Lakers',
              nickname: 'Lakers'
            }
          },
          hTeam: {
            teamId: '1610612743',
            triCode: 'DEN',
            score: '116',
            linescore: [
              {
                score: '31'
              },
              {
                score: '24'
              },
              {
                score: '32'
              },
              {
                score: '24'
              },
              {
                score: '5'
              }
            ],
            team: {
              city: 'Denver',
              fullName: 'Denver Nuggets',
              nickname: 'Nuggets'
            }
          }
        }
      ]
    }
  }
}
