import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import _get from 'lodash.get'

const GAME_STATUS = {
  LIVE: 'LIVE',
  ENDED: 'ENDED',
  TBP: 'TO_BE_PLAYED'
}

function getStatus(game) {
  if (game.isGameActivated) {
    return GAME_STATUS.LIVE
  }
  if (game.endTimeUTC) {
    return GAME_STATUS.ENDED
  }
  return GAME_STATUS.TBP
}

function ScoreCard({ game }) {
  // console.log(game)
  const { vTeam, hTeam } = game
  const teams = [vTeam, hTeam]
  const isHomeTeamWinner = parseInt(hTeam.score) > parseInt(vTeam.score)
  const watch = _get(game, 'watch.broadcast.broadcasters.national[0]')
  console.log(watch)
  const status = getStatus(game)
  return (
    <div className="ScoreCard">
      <div className=" bg-white p-2 rounded-lg shadow-md border">
        <div className="flex items-stretch">
          <LogoColumn teams={teams} game={game} status={status} />
          <NameColumn teams={teams} />
          <div className="flex flex-col flex-grow">
            <TeamLineHeader {...{ game, status, watch }} />
            <TeamLine team={vTeam} win={!isHomeTeamWinner} />
            <TeamLine team={hTeam} win={isHomeTeamWinner} />
          </div>
        </div>
      </div>
    </div>
  )
}

function LogoColumn({ teams, game, status }) {
  return (
    <div className="flex flex-col">
      <div className="relative border-b  mt-2 text-gray-600 text-xs uppercase font-extrabold tracking-wide">
        &nbsp;
        <div className="absolute left-0 top-0 whitespace-no-wrap">
          {status === GAME_STATUS.TBP
            ? moment(game.startTimeUTC)
                .tz(moment.tz.guess())
                .format('LT z')
            : status === GAME_STATUS.ENDED
            ? 'Final'
            : null}
        </div>
      </div>
      {teams.map((team, i) => (
        <div className="w-8 h-8 md:w-16 mr-2 mt-2" key={i}>
          <img
            src={`https://cdn.nba.net/assets/logos/teams/secondary/web/${team.triCode}.svg`}
            alt={team.team ? team.team.fullName : team.triCode}
            className="md:w-full h-8 object-cover object-center"
          />
        </div>
      ))}
    </div>
  )
}

function NameColumn({ teams }) {
  return (
    <div className="flex flex-col flex-1" style={{ maxWidth: '10rem' }}>
      <div className="border-b mt-2 text-gray-600 text-xs uppercase font-extrabold tracking-wide">
        &nbsp;
      </div>
      {teams.map((team, i) => (
        <div className="flex-grow flex items-center mt-2" key={i}>
          <span className="truncate">
            {team.team ? team.team.nickname : team.triCode}
          </span>
        </div>
      ))}
    </div>
  )
}

function TeamLineHeader({ game, status, watch }) {
  const periods = game.hTeam.linescore.map((q, i, arr) => {
    if (i < 4) {
      return i + 1
    }
    if (arr.length > 5) {
      return `OT${i - 3}`
    }
    return 'OT'
  })
  return (
    <div className="flex flex-row mt-2 border-b  ">
      {periods.map((q, i) => (
        <div
          className="sm:hidden lg:block w-10 text-right text-gray-600 text-xs uppercase font-extrabold tracking-wide"
          key={`${i}`}
        >
          {q}
        </div>
      ))}
      <div className="ml-auto text-right text-gray-600 text-xs uppercase font-extrabold tracking-wide">
        {status === GAME_STATUS.TBP && watch ? (
          <span>
            &nbsp;
            <abbr title={watch.shortName}>
              <svg
                className="text-green-600 stroke-current h-4 inline-block "
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            </abbr>
          </span>
        ) : status === GAME_STATUS.ENDED ? (
          'total'
        ) : (
          <>&nbsp;</>
        )}
      </div>
    </div>
  )
}

function TeamLine({ team, gameId, win }) {
  // console.log(team, gameId)
  if (!team.team) {
    team.team = {
      nickname: team.triCode,
      fullName: team.triCode
    }
  }
  return (
    <div className="flex flex-grow mt-2">
      {team.linescore.map((q, i) => (
        <div
          className="self-center sm:hidden lg:block w-10 text-right text-gray-600 text-xs font-semibold tracking-wide"
          key={`${i}`}
        >
          {q.score}
        </div>
      ))}
      <div
        className={`${
          win ? '' : 'text-gray-500'
        } ml-auto text-right font-bold flex-grow self-center`}
      >
        {team.score}
      </div>
    </div>
  )
}

export default ScoreCard
