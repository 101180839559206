/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const scores = /* GraphQL */ `
  query Scores($query: QueryScoresQueryInput!) {
    scores(query: $query) {
      numGames
      games {
        watch {
          broadcast {
            broadcasters {
              national {
                shortName
                longName
              }
            }
          }
        }
        seasonStageId
        seasonYear
        gameId
        isGameActivated
        startTimeUTC
        endTimeUTC
        clock
        attendance
        vTeam {
          teamId
          triCode
          score
          linescore {
            score
          }
          team {
            city
            fullName
            nickname
          }
        }
        hTeam {
          teamId
          triCode
          score
          linescore {
            score
          }
          team {
            city
            fullName
            nickname
          }
        }
      }
    }
  }
`
